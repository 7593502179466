




























import { defineComponent, PropType, computed } from '@nuxtjs/composition-api';
import type { CardData } from './Card.vue';
import useWindowResize from '~/hooks/useResizeWindow';

type ServiceSectionData = {
  // TODO: replace with the two possible values
  code: string;
  elements: CardData[];
};

export default defineComponent({
  name: 'ServiceSectionGallery',
  props: {
    data: {
      type: Object as PropType<ServiceSectionData>,
      required: true
    }
  },
  setup(props) {
    const { isDesktop } = useWindowResize();

    const formattedData = computed(() => {
      const defaultData = {
        mobileType: 'slider',
        mobileCardSizeClass: 'sm-cols-5',
        desktopCarouselPageItems: 3,
        desktopCardSizeClass: 'four-columns'
      };

      const data = { ...defaultData };
      if (props.data?.code === 'servicesection_container_stacked') {
        data.mobileType = 'stacked';
        data.mobileCardSizeClass = 'sm-cols-3';
      }

      if (props.data?.elements?.[0]?.number_of_columns === 3) {
        data.desktopCarouselPageItems = 4;
        data.desktopCardSizeClass = 'three-columns';
      }

      return data;
    });

    const carouselSettings = computed(() => ({
      perView: formattedData.value.desktopCarouselPageItems,
      breakpoints: {
        1223: {
          perView: formattedData.value.desktopCarouselPageItems
        }
      }
    }));

    return {
      isDesktop,
      formattedData,
      carouselSettings
    };
  }
});
